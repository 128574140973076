<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len -->
  <!-- Generator: Adobe Illustrator 21.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 36.7 40.6"
    style="enable-background: new 0 0 36.7 40.6"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          fill="#424143"
          d="M13.79,17.6c-0.64-0.21-1.07-0.81-1.07-1.48V7.46C12.72,7.2,12.52,7,12.26,7H7.2
            C6.95,7,6.74,7.2,6.74,7.46v8.67c0,0.67-0.43,1.27-1.07,1.48c-1.84,0.61-3.08,2.33-3.08,4.27v9.97c0,1.52,1.24,2.76,2.76,2.76
            h8.74c1.52,0,2.76-1.24,2.76-2.76v-1.38v-8.59C16.86,19.93,15.63,18.22,13.79,17.6z M7.66,8.15c0-0.13,0.1-0.23,0.23-0.23h3.68
            c0.13,0,0.23,0.1,0.23,0.23v0.92c0,0.13-0.1,0.23-0.23,0.23H7.89c-0.13,0-0.23-0.1-0.23-0.23V8.15z M15.94,29.31
            c0,0.13-0.1,0.23-0.23,0.23H6.97c-0.13,0-0.23-0.1-0.23-0.23v-5.06c0-0.13,0.1-0.23,0.23-0.23h8.74c0.13,0,0.23,0.1,0.23,0.23
            V29.31z M15.94,22.87c0,0.13-0.1,0.23-0.23,0.23H6.28c-0.25,0-0.46,0.21-0.46,0.46V30c0,0.25,0.21,0.46,0.46,0.46h9.43
            c0.13,0,0.23,0.1,0.23,0.23v1.15c0,1.02-0.83,1.84-1.84,1.84H5.36c-1.02,0-1.84-0.83-1.84-1.84v-9.97c0-1.54,0.98-2.91,2.45-3.39
            c1.01-0.34,1.69-1.28,1.69-2.35v-5.68c0-0.13,0.1-0.23,0.23-0.23h3.68c0.13,0,0.23,0.1,0.23,0.23v5.68c0,1.07,0.68,2.01,1.69,2.35
            c1.46,0.49,2.45,1.85,2.45,3.39V22.87z"
        />
      </g>
    </g>
    <g>
      <path
        fill="#424143"
        d="M20.89,9.11v23.87c0,0.89,0.73,1.62,1.62,1.62h10.22c0.89,0,1.62-0.73,1.62-1.62V9.11H20.89z M33.15,10.31
        v2.68H22.09v-2.68H33.15z M32.73,33.4H22.51c-0.23,0-0.42-0.19-0.42-0.42V14.19h6.67c-0.02,0.02-0.03,0.04-0.05,0.06l-1.7,2.62
        c-0.11,0.17-0.15,0.38-0.11,0.58c0.04,0.2,0.16,0.37,0.33,0.49l2.54,1.65c0.13,0.08,0.27,0.12,0.42,0.12c0.26,0,0.5-0.13,0.65-0.35
        l1.7-2.62c0.11-0.17,0.15-0.38,0.11-0.58c-0.04-0.2-0.16-0.37-0.33-0.49l-2.29-1.49h3.12v18.79C33.15,33.21,32.96,33.4,32.73,33.4z
         M29.49,15.26l1.82,1.18l-1.24,1.9l-1.82-1.18L29.49,15.26z"
      />
      <path
        fill="#424143"
        d="M32.24,24.41l-2.93-1.1c-0.09-0.03-0.18-0.05-0.27-0.05c-0.32,0-0.61,0.2-0.72,0.5l-1.06,2.84
        c-0.15,0.4,0.05,0.84,0.45,0.99l2.93,1.1c0.09,0.03,0.18,0.05,0.27,0.05c0.32,0,0.61-0.2,0.72-0.5l1.06-2.84
        C32.84,25,32.64,24.56,32.24,24.41z M30.65,27.41l-2.13-0.8l0.76-2.03l2.13,0.8L30.65,27.41z"
      />
      <path
        fill="#424143"
        d="M25.17,24.17c0.14,0,0.28-0.04,0.4-0.11l2.68-1.61c0.36-0.22,0.48-0.69,0.27-1.06l-1.56-2.6
        c-0.14-0.23-0.39-0.37-0.66-0.37c-0.14,0-0.28,0.04-0.4,0.11l-2.68,1.61c-0.36,0.22-0.48,0.69-0.27,1.06l1.56,2.6
        C24.64,24.03,24.9,24.17,25.17,24.17z M26.15,19.79l1.12,1.86l-1.95,1.17l-1.12-1.86L26.15,19.79z"
      />
      <path
        fill="#424143"
        d="M27.49,28.01h-3.13c-0.43,0-0.77,0.35-0.77,0.77v3.03c0,0.43,0.35,0.77,0.77,0.77h3.13
        c0.43,0,0.77-0.35,0.77-0.77v-3.03C28.26,28.36,27.91,28.01,27.49,28.01z M27.06,31.38h-2.27v-2.17h2.27V31.38z"
      />
    </g>
  </svg>
</template>
