<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 63.8 51"
    style="enable-background:new 0 0 63.8 51;"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          :fill="color"
          d="M28.7,1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1v10.6c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1V1.1z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M39.3,5.3c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1v6.4c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1V5.3z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M18.1,5.3c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1v6.4c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1V5.3z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M0,49.9C0,50.5,0.5,51,1.1,51h53.1c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1H27.6H1.1C0.5,48.9,0,49.4,0,49.9z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M47.8,38.7c0.2-0.3,0.6-0.3,0.8-0.1c1.6,1.1,3.5,1.7,5.5,1.7c5.3,0,9.6-4.3,9.6-9.6c0-4.8-3.6-8.9-8.3-9.5
c-0.3,0-0.4-0.3-0.4-0.6c0.1-0.9,0.2-1.8,0.2-2.7c0-0.6-0.5-1.1-1-1.1H1.1c-0.6,0-1.1,0.5-1,1c0.5,17.1,12.7,30.8,27.6,30.8
C35.6,48.9,42.8,45,47.8,38.7z M61.6,30.8c0,4.1-3.3,7.4-7.4,7.4c-1.6,0-3.1-0.5-4.4-1.4c-0.2-0.2-0.3-0.4-0.1-0.7
c2.4-3.6,4.1-7.8,4.9-12.3c0.1-0.3,0.3-0.4,0.6-0.4C58.8,24,61.6,27.1,61.6,30.8z M11.7,39.7c0,0.2-0.2,0.3-0.4,0.2
c-5-4.9-8.5-12.1-9.1-20.1c0-0.3,0.2-0.6,0.5-0.6h12.7c0.3,0,0.5,0.2,0.5,0.5v7.4c0,0.3-0.2,0.5-0.5,0.5h-2.7
c-0.6,0-1.1,0.5-1.1,1.1V39.7z M19.7,29.8c0.3,0,0.5,0.2,0.5,0.5v7.4c0,0.3-0.2,0.5-0.5,0.5h-5.3c-0.3,0-0.5-0.2-0.5-0.5v-7.4
c0-0.3,0.2-0.5,0.5-0.5H19.7z M27.6,46.8c-5.8,0-11.1-2.2-15.3-6c-0.1-0.1-0.1-0.4,0.1-0.4h8.8c0.6,0,1.1-0.5,1.1-1.1V28.7
c0-0.6-0.5-1.1-1.1-1.1h-2.7c-0.3,0-0.5-0.2-0.5-0.5v-7.4c0-0.3,0.2-0.5,0.5-0.5h33.9c0.3,0,0.5,0.3,0.5,0.5
C51.9,34.8,40.9,46.8,27.6,46.8z"
        />
      </g>
    </g>
  </svg>
</template>
