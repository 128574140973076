<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len -->
  <!-- Generator: Adobe Illustrator 21.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg :width="w" :height="h" role="presentation" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 73.3 53.8"
    style="enable-background:new 0 0 73.3 53.8;" xml:space="preserve">
    <g>
      <path :fill="color" d="M70.3,24.4c-0.3,0-0.6-0.3-0.6-0.6V1.2c0-0.7-0.5-1.2-1.2-1.2H4.9C4.2,0,3.7,0.5,3.7,1.2v22.6
c0,0.3-0.3,0.6-0.6,0.6H1.2C0.5,24.4,0,25,0,25.7v19.6c0,0.7,0.5,1.2,1.2,1.2h1.8c0.3,0,0.6,0.3,0.6,0.6v5.5c0,0.7,0.5,1.2,1.2,1.2
c0.7,0,1.2-0.5,1.2-1.2V47c0-0.3,0.3-0.6,0.6-0.6h59.9c0.3,0,0.6,0.3,0.6,0.6v5.5c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2V47
c0-0.3,0.3-0.6,0.6-0.6h1.8c0.7,0,1.2-0.5,1.2-1.2V25.7c0-0.7-0.5-1.2-1.2-1.2H70.3z M6.1,3.1c0-0.3,0.3-0.6,0.6-0.6h59.9
c0.3,0,0.6,0.3,0.6,0.6v20.8c0,0.3-0.3,0.6-0.6,0.6h-1.2c-0.3,0-0.6-0.3-0.6-0.6V13.4c0-0.7-0.5-1.2-1.2-1.2H39.1
c-0.7,0-1.2,0.5-1.2,1.2v10.4c0,0.3-0.3,0.6-0.6,0.6H36c-0.3,0-0.6-0.3-0.6-0.6V13.4c0-0.7-0.5-1.2-1.2-1.2H9.8
c-0.7,0-1.2,0.5-1.2,1.2v10.4c0,0.3-0.3,0.6-0.6,0.6H6.7c-0.3,0-0.6-0.3-0.6-0.6V3.1z M61.7,14.7c0.3,0,0.6,0.3,0.6,0.6v8.6
c0,0.3-0.3,0.6-0.6,0.6H40.9c-0.3,0-0.6-0.3-0.6-0.6v-8.6c0-0.3,0.3-0.6,0.6-0.6H61.7z M32.4,14.7c0.3,0,0.6,0.3,0.6,0.6v8.6
c0,0.3-0.3,0.6-0.6,0.6H11.6c-0.3,0-0.6-0.3-0.6-0.6v-8.6c0-0.3,0.3-0.6,0.6-0.6H32.4z M70.9,43.4c0,0.3-0.3,0.6-0.6,0.6H3.1
c-0.3,0-0.6-0.3-0.6-0.6v-3.7c0-0.3,0.3-0.6,0.6-0.6h67.2c0.3,0,0.6,0.3,0.6,0.6V43.4z M70.9,36c0,0.3-0.3,0.6-0.6,0.6H3.1
c-0.3,0-0.6-0.3-0.6-0.6v-8.6c0-0.3,0.3-0.6,0.6-0.6h67.2c0.3,0,0.6,0.3,0.6,0.6V36z" />
    </g>
  </svg>
</template>
