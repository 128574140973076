<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 56 44"
    style="enable-background:new 0 0 56 44;"
    xml:space="preserve"
  >
    <path
      :fill="color"
      d="M53,0H3C1.3,0,0,1.3,0,3v32c0,1.7,1.3,3,3,3h21v4h-5c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h18c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1
h-5v-4h21c1.7,0,3-1.3,3-3V3C56,1.3,54.7,0,53,0z M30,42h-4v-4h4V42z M54,35c0,0.6-0.4,1-1,1H3c-0.6,0-1-0.4-1-1v-1h52V35z M54,32H2
V3c0-0.6,0.4-1,1-1h50c0.6,0,1,0.4,1,1V32z M40.4,10.1c-0.5-0.2-1.1,0-1.3,0.4L34,20.8l-5.1-10.2C28.7,10.2,28.4,10,28,10H16
c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h5v11c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1V12h4.4l5.7,11.4c0.2,0.3,0.5,0.6,0.9,0.6
c0.4,0,0.7-0.2,0.9-0.6l6-12C41.1,11,40.9,10.4,40.4,10.1z"
    />
  </svg>
</template>
