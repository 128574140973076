<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 60 56"
    style="enable-background:new 0 0 60 56;"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          :fill="color"
          d="M6,13c0,3.9,3.1,7,7,7c3.9,0,7-3.1,7-7c0-3.9-3.1-7-7-7C9.1,6,6,9.1,6,13z M18,13c0,2.8-2.2,5-5,5c-2.8,0-5-2.2-5-5
c0-2.8,2.2-5,5-5C15.8,8,18,10.2,18,13z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M49,8c-0.6,0-1,0.4-1,1s0.4,1,1,1h10c0.6,0,1-0.4,1-1s-0.4-1-1-1H49z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M48,15c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6C48.4,14,48,14.4,48,15z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M56,3c0-0.6-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1s0.4,1,1,1h6C55.6,4,56,3.6,56,3z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M0,13c0,4.3,2.1,8.3,5.7,10.7C5.9,23.9,6,24.2,6,24.4V43c0,0.6,0.4,1,1,1h4.5c0.3,0,0.5,0.2,0.5,0.5V55c0,0.6,0.4,1,1,1
c0.6,0,1-0.4,1-1V44.5c0-0.3,0.2-0.5,0.5-0.5H19c0.6,0,1-0.4,1-1V24.4c0-0.3,0.2-0.6,0.4-0.8c1.9-1.3,3.4-3.1,4.4-5.2
c0.1-0.3,0.4-0.4,0.7-0.4H43c0.6,0,1-0.4,1-1V1c0-0.6-0.4-1-1-1H13C5.8,0,0,5.8,0,13z M42,15.5c0,0.3-0.2,0.5-0.5,0.5H24.6
c-0.6,0-1.1,0.4-1.3,0.9c-0.8,2.2-2.4,4.1-4.4,5.4c-0.5,0.3-0.9,0.9-0.9,1.5v17.7c0,0.3-0.2,0.5-0.5,0.5h-9C8.2,42,8,41.8,8,41.5
V23.8c0-0.6-0.3-1.2-0.8-1.5C4,20.3,2,16.8,2,13C2,6.9,6.9,2,13,2h28.5C41.8,2,42,2.2,42,2.5V15.5z"
        />
      </g>
    </g>
  </svg>
</template>
