<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len -->
  <!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 50.7 56.9"
    style="enable-background:new 0 0 50.7 56.9;"
    xml:space="preserve"
  >
    <g>
      <path
        :fill="color"
        d="M48.6,25.1H2.1l0-1C2.7,11.6,12.9,1.9,25.3,1.9c12.5,0,22.7,9.7,23.2,22.2L48.6,25.1z M4.2,23.1h42.3
c-1-10.9-10.1-19.2-21.2-19.2S5.2,12.2,4.2,23.1z"
      />
      <g>
        <path
          :fill="color"
          d="M40.9,21.6c-0.9-4.3-3.3-8.3-6.7-11.1L35.4,9c3.8,3.1,6.4,7.5,7.4,12.2L40.9,21.6z"
        />
      </g>
      <path
        :fill="color"
        d="M48.7,29.7H2c-1.1,0-2-1.3-2-3.2c0-1.9,0.8-3.2,2-3.2h46.8c1.2,0,2,1.3,2,3.2C50.7,28.4,49.9,29.7,48.7,29.7z M2.2,27.7
h46.2c0.1-0.2,0.2-0.6,0.2-1.2c0-0.6-0.1-1-0.2-1.2H2.2C2.1,25.4,2,25.9,2,26.5C2,27.1,2.1,27.5,2.2,27.7z"
      />
      <path
        :fill="color"
        d="M12.7,50.5c-0.1-0.2-3.1-4.4-0.9-9.8c1-2.5,0.4-8.5,0-10.5l-0.3-0.2l0-0.5c0-0.2,0-0.9,0.7-1.2l0.6-0.3l0.3,0.3
c0.9-0.2,2.3-0.3,3.5,0.5c1,0.6,1.5,1.7,1.7,3.2c0.6,4.4,1.7,5.8,2.5,6.2c0.7,0.3,1.6,0.2,2.8-0.5l1,1.7c-1.8,1-3.4,1.2-4.7,0.6
c-1.9-0.9-3.1-3.5-3.6-7.8c-0.1-0.9-0.4-1.5-0.9-1.8c-0.4-0.3-1-0.3-1.6-0.3c0.6,2.9,0.9,8.7-0.2,11.3c-1.8,4.3,0.7,7.8,0.7,7.8
L12.7,50.5z"
      />
      <path
        :fill="color"
        d="M22.9,56.8h-2v-3.7l20.3-22.8c-0.7-0.2-1.9-0.2-3.3,1.1c-3.5,3.2-9,8.9-9,8.9l-1.4-1.4c0.1-0.1,5.6-5.7,9.1-9
c3-2.8,5.9-1.7,6.9-0.7l0.7,0.7L22.9,53.8V56.8z"
      />
      <path
        :fill="color"
        d="M12.1,56.9l-1.9-0.7l2.7-6.7c0-0.1,1.6-4.7,6.3-7.8c4.3-2.8,5.4-6,5.8-8.4c0.7-3.6,1.4-4.7,5.3-5.1l1.4-0.2l-3.4,14.7
l-1.9-0.5l2.8-11.8c-1.5,0.3-1.7,1-2.1,3.3c-0.5,2.8-1.8,6.4-6.7,9.7c-4.1,2.7-5.5,6.8-5.5,6.8L12.1,56.9z"
      />
      <rect x="24.3" width="2" height="2.9" />
    </g>
  </svg>
</template>
