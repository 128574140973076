<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconAc',
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len */ -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 60 49"
    aria-labelledby="acicon"
    role="presentation"
  >
    <title id="acicon">Air Conditioning Icon</title>
    <g>
      <g>
        <path
          :fill="color"
          d="M1,0C0.4,0,0,0.4,0,1v29c0,0.6,0.4,1,1,1h58c0.6,0,1-0.4,1-1V1c0-0.6-0.4-1-1-1H1z M58,28.5c0,0.3-0.2,0.5-0.5,0.5h-55
C2.2,29,2,28.8,2,28.5v-26C2,2.2,2.2,2,2.5,2h55C57.8,2,58,2.2,58,2.5V28.5z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M54,24c0-0.6-0.4-1-1-1H7c-0.6,0-1,0.4-1,1s0.4,1,1,1h46C53.6,25,54,24.6,54,24z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M54,20c0-0.6-0.4-1-1-1H7c-0.6,0-1,0.4-1,1s0.4,1,1,1h46C53.6,21,54,20.6,54,20z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M10,7c0-0.6-0.4-1-1-1H7C6.4,6,6,6.4,6,7s0.4,1,1,1h2C9.6,8,10,7.6,10,7z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M16,7c0-0.6-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2C15.6,8,16,7.6,16,7z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M17.1,37.3c0.6-0.5,1.4-1.2,1.7-2.3c0.2-0.5-0.3-1-0.9-1c-0.6,0-1,0.5-1.3,0.9c-0.2,0.3-0.5,0.5-0.9,0.8
C15.1,36.4,14,37.3,14,39c0,1.7,1.1,2.6,1.9,3.3c0.7,0.6,1.1,1,1.1,1.7c0,0.7-0.4,1.1-1.1,1.7c-0.6,0.5-1.4,1.2-1.7,2.3
c-0.2,0.5,0.3,1,0.9,1c0.6,0,1-0.5,1.3-0.9c0.2-0.3,0.5-0.5,0.9-0.8c0.8-0.7,1.9-1.6,1.9-3.3c0-1.7-1.1-2.6-1.9-3.3
c-0.7-0.6-1.1-1-1.1-1.7C16,38.3,16.4,37.9,17.1,37.3z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M26.1,37.3c0.6-0.5,1.4-1.2,1.7-2.3c0.2-0.5-0.3-1-0.9-1c-0.6,0-1,0.5-1.3,0.9c-0.2,0.3-0.5,0.5-0.9,0.8
C24.1,36.4,23,37.3,23,39c0,1.7,1.1,2.6,1.9,3.3c0.7,0.6,1.1,1,1.1,1.7c0,0.7-0.4,1.1-1.1,1.7c-0.6,0.5-1.4,1.2-1.7,2.3
c-0.2,0.5,0.3,1,0.9,1c0.6,0,1-0.5,1.3-0.9c0.2-0.3,0.5-0.5,0.9-0.8c0.8-0.7,1.9-1.6,1.9-3.3c0-1.7-1.1-2.6-1.9-3.3
c-0.7-0.6-1.1-1-1.1-1.7C25,38.3,25.4,37.9,26.1,37.3z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M35.1,37.3c0.6-0.5,1.4-1.2,1.7-2.3c0.2-0.5-0.3-1-0.9-1c-0.6,0-1,0.5-1.3,0.9c-0.2,0.3-0.5,0.5-0.9,0.8
C33.1,36.4,32,37.3,32,39c0,1.7,1.1,2.6,1.9,3.3c0.7,0.6,1.1,1,1.1,1.7c0,0.7-0.4,1.1-1.1,1.7c-0.6,0.5-1.4,1.2-1.7,2.3
c-0.2,0.5,0.3,1,0.9,1c0.6,0,1-0.5,1.3-0.9c0.2-0.3,0.5-0.5,0.9-0.8c0.8-0.7,1.9-1.6,1.9-3.3c0-1.7-1.1-2.6-1.9-3.3
c-0.7-0.6-1.1-1-1.1-1.7C34,38.3,34.4,37.9,35.1,37.3z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M44.1,37.3c0.6-0.5,1.4-1.2,1.7-2.3c0.2-0.5-0.3-1-0.9-1c-0.6,0-1,0.5-1.3,0.9c-0.2,0.3-0.5,0.5-0.9,0.8
C42.1,36.4,41,37.3,41,39c0,1.7,1.1,2.6,1.9,3.3c0.7,0.6,1.1,1,1.1,1.7c0,0.7-0.4,1.1-1.1,1.7c-0.6,0.5-1.4,1.2-1.7,2.3
c-0.2,0.5,0.3,1,0.9,1c0.6,0,1-0.5,1.3-0.9c0.2-0.3,0.5-0.5,0.9-0.8c0.8-0.7,1.9-1.6,1.9-3.3c0-1.7-1.1-2.6-1.9-3.3
c-0.7-0.6-1.1-1-1.1-1.7C43,38.3,43.4,37.9,44.1,37.3z"
        />
      </g>
    </g>
  </svg>
</template>
