<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 48.1 54"
    style="enable-background:new 0 0 48.1 54;"
    xml:space="preserve"
  >
    <g>
      <path
        :fill="color"
        d="M7.7,54c-2.3,0-4.2-1.9-4.2-4.2l0,0V5.2h1.7v44.7c0,1.4,1.1,2.5,2.5,2.5l0,0h32.6c1.4,0,2.5-1.1,2.5-2.5l0,0V5.2h1.7v44.7
c0,2.3-1.9,4.2-4.2,4.2l0,0L7.7,54L7.7,54z"
      />
      <path
        :fill="color"
        d="M0,6V0h48.1v5.2V6H0L0,6z M47.2,5.2V4.3V5.2L47.2,5.2z M1.7,4.3h44.7l0-2.6H1.7V4.3L1.7,4.3z"
      />
      <path
        :fill="color"
        d="M11.5,18.1c-2.6,0-4.6-2.1-4.6-4.6l0,0v-2.2c0-2.6,2.1-4.6,4.6-4.6l0,0h7.7c2.6,0,4.6,2.1,4.6,4.6l0,0l0,2.2
c0,2.6-2.1,4.6-4.6,4.6l0,0H11.5L11.5,18.1z M11.5,16.4h7.7c1.6,0,3-1.3,3-3l0,0l0-2.2c0-1.6-1.3-3-3-3l0,0h-7.7c-1.6,0-3,1.3-3,3
l0,0v2.2C8.5,15.1,9.8,16.4,11.5,16.4L11.5,16.4z"
      />
      <path
        :fill="color"
        d="M28.9,18.1c-2.6,0-4.6-2.1-4.6-4.6l0,0v-2.2c0-2.6,2.1-4.6,4.6-4.6l0,0h7.7c2.6,0,4.6,2.1,4.6,4.6l0,0v2.2
c0,2.6-2.1,4.6-4.6,4.6l0,0H28.9L28.9,18.1z M25.9,11.3l0,2.2c0,1.6,1.3,3,3,3l0,0h7.7c1.6,0,3-1.3,3-3l0,0v-2.2c0-1.6-1.3-3-3-3
l0,0h-7.7C27.2,8.3,25.9,9.6,25.9,11.3L25.9,11.3z"
      />
      <path
        :fill="color"
        d="M35.7,29.2h-0.8v-8.9l1.4-0.6l8,8l-0.6,1.4H35.7L35.7,29.2z M41.7,27.5l-5.2-5.2v5.2H41.7L41.7,27.5z"
      />
      <path
        :fill="color"
        d="M4.4,21.2c-0.5,0-0.8-0.4-0.8-0.8l0,0c0-0.5,0.4-0.8,0.8-0.8l0,0h31.3c0.5,0,0.8,0.4,0.8,0.8l0,0c0,0.5-0.4,0.8-0.8,0.8
l0,0H4.4L4.4,21.2z"
      />
    </g>
  </svg>
</template>
