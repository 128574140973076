<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 51.2 42.6"
    style="enable-background:new 0 0 51.2 42.6;"
    xml:space="preserve"
  >
    <g>
      <path
        :fill="color"
        d="M21.7,0.5c-0.2-0.3-0.6-0.5-1-0.5H1.2C0.9,0,0.6,0.1,0.3,0.4C0.1,0.6,0,1,0,1.3C0.4,11.4,4.2,19,10.8,23.7l-7,18.9h2.7
l2.6-7l14.3,0l0.9-2.5l-14.2,0l3-8.1c3.6,2,7.8,3.4,12.8,3.9l0.9-2.4c-11.2-1.2-22.7-6.7-24-24.1H19c-0.8,2.9-1.9,9,1.6,14.2
c1.7,2.5,4.2,4.4,7.5,5.8l0.9-2.3c-2.8-1.1-4.9-2.7-6.3-4.8c-2.6-3.8-2.1-8.5-1.5-11.3l10.9,15.6l-8.4,23h2.7l3.8-10.4h10.8
l7.3,10.4h3.1L21.7,0.5C21.7,0.5,21.7,0.5,21.7,0.5z M39.2,29.7h-8.1l2.8-7.6L39.2,29.7z M42.9,25.8l1.6,2.2c1-0.2,1.6-0.4,1.7-0.4
c0.5-0.1,0.9-0.6,0.9-1.2v-3c0-0.3-0.1-0.7-0.4-0.9c-0.2-0.2-0.6-0.4-0.9-0.3c-1.9,0.1-3.7,0.1-5.4,0.1l1.8,2.5c0,0,0,0,0.1,0
c0.8,0,1.6,0,2.4,0v0.7C44.2,25.6,43.6,25.7,42.9,25.8z"
      />
    </g>
  </svg>
</template>
