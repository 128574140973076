<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 47.8 56.9"
    style="enable-background:new 0 0 47.8 56.9;"
    xml:space="preserve"
  >
    <g>
      <path
        :fill="color"
        d="M27.8,8.1H6.5v12.6h21.4V8.1z M26.5,19H8.1V9.5h18.4V19z"
      />
      <path
        :fill="color"
        d="M47.7,47.5V24.9c0-1-0.4-1.8-1.1-2.5c-0.7-0.6-1.6-0.9-2.6-0.8l-9.8,1.2V6c0-3.3-2.7-6-6-6H6C2.7,0,0,2.7,0,6v37.9
c0,3.3,2.7,6,6,6h7.9c0,0.1,0,0.2,0,0.3c0,3.8,3.1,6.8,6.8,6.8c3.8,0,6.8-3.1,6.8-6.8h0V33l17-2.1v10.9c-1-0.6-2.2-1-3.5-1
c-3.8,0-6.8,3.1-6.8,6.8s3.1,6.8,6.8,6.8c3.8,0,6.8-3.1,6.8-6.8C47.8,47.6,47.8,47.5,47.7,47.5z M4.9,48.3c-1.6,0-3-1.3-3-2.9V4.9
c0-1.6,1.3-2.9,3-2.9h24.7c1.6,0,3,1.3,3,2.9v18.5l-3.9,0.5c-2,0.2-3.6,2-3.6,3.9v18.1c-1.1-0.7-2.5-1.1-3.9-1.1
c-2.7,0-5.1,1.4-6.4,3.5H4.9z M20.7,55.4c-2.6,0-4.7-2-4.7-4.5c0-2.5,2.1-4.5,4.7-4.5c2.6,0,4.8,2,4.8,4.5
C25.4,53.4,23.3,55.4,20.7,55.4z M26.7,31.1l0.2-4c0-0.3,0.3-0.7,0.4-0.7l18.1-2.7l-0.2,4.6L26.7,31.1z M41.1,52.8
c-2.8,0-5.1-2.3-5.1-5.1c0-2.8,2.3-5.1,5.1-5.1c2.8,0,5,2.2,5.1,5v0.1h0v0C46.1,50.5,43.9,52.8,41.1,52.8z"
      />
    </g>
  </svg>
</template>
