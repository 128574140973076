<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconAlarm',
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len */ -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 51.1 55.4"
    aria-labelledby="IconAlarm"
    role="presentation"
  >
    <title id="IconAlarm">Alarm Icon</title>
    <path
      :fill="color"
      class="st0"
      d="M25.6,8.5C12.6,8.5,2.1,19,2.1,31.9c0,5.6,2,10.7,5.3,14.8l-2.5,3.6c-1,1.4-0.7,3.4,0.8,4.4
c0.6,0.4,1.2,0.6,1.8,0.6c1,0,2-0.5,2.6-1.4l2-2.9c3.8,2.7,8.4,4.3,13.5,4.3c5,0,9.6-1.6,13.5-4.3l2,2.9c0.6,0.9,1.6,1.4,2.6,1.4
c0.6,0,1.3-0.2,1.8-0.6c1.4-1,1.8-3,0.8-4.4l-2.5-3.6c3.3-4,5.3-9.2,5.3-14.8C49,19,38.5,8.5,25.6,8.5z M8.3,52.8
c-0.3,0.5-1,0.6-1.5,0.3c-0.5-0.3-0.6-1-0.3-1.5l2.3-3.2c0.5,0.5,1,1,1.6,1.5L8.3,52.8z M44.5,51.6c0.3,0.5,0.2,1.1-0.3,1.5
c-0.5,0.3-1.1,0.2-1.5-0.3l-2.1-3c0.5-0.5,1.1-1,1.6-1.5L44.5,51.6z M25.6,53.2c-11.7,0-21.3-9.6-21.3-21.3
c0-11.7,9.6-21.3,21.3-21.3c11.7,0,21.3,9.6,21.3,21.3C46.8,43.7,37.3,53.2,25.6,53.2z M26.6,30.1V16c0-0.6-0.5-1.1-1.1-1.1
c-0.6,0-1.1,0.5-1.1,1.1v14.1c-0.6,0.4-1.1,1-1.1,1.8c0,0.2,0,0.4,0.1,0.5l-8.3,8.3c-0.4,0.4-0.4,1.1,0,1.5c0.2,0.2,0.5,0.3,0.8,0.3
c0.3,0,0.5-0.1,0.8-0.3L25,34c0.2,0,0.4,0.1,0.5,0.1c1.2,0,2.1-1,2.1-2.1C27.7,31.2,27.3,30.5,26.6,30.1z M3.3,15.7l14.3-10
c0.5-0.3,0.6-1,0.3-1.5C16,1.6,13,0,9.8,0c-2,0-4,0.6-5.6,1.8C2,3.3,0.6,5.5,0.2,8.1c-0.5,2.6,0.1,5.2,1.6,7.3
c0.2,0.3,0.5,0.5,0.9,0.5C2.9,15.9,3.1,15.8,3.3,15.7z M2.2,8.5c0.4-2,1.5-3.8,3.2-4.9c1.3-0.9,2.8-1.4,4.4-1.4
c2.1,0,4.2,0.9,5.6,2.4L3,13.3C2.2,11.8,2,10.1,2.2,8.5z M51,8.1c-0.5-2.6-1.9-4.8-4-6.3C45.3,0.6,43.3,0,41.3,0
c-3.2,0-6.2,1.6-8,4.2c-0.3,0.5-0.2,1.1,0.3,1.5l14.3,10c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.7-0.2,0.9-0.5C50.8,13.3,51.4,10.7,51,8.1
z M48.1,13.3L35.7,4.6c1.4-1.5,3.5-2.4,5.6-2.4c1.6,0,3.1,0.5,4.4,1.4c1.7,1.2,2.8,2.9,3.2,4.9C49.2,10.1,48.9,11.8,48.1,13.3z"
    />
  </svg>
</template>
