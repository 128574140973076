<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 64.9 64.9"
    style="enable-background:new 0 0 64.9 64.9;"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          :fill="color"
          d="M34.3,18.3c-20.5,0-30.5,27.5-30.9,28.7l-0.1,0.4c-0.2,0.5,0.2,0.9,0.7,0.9h58.4c0.6,0,1-0.4,1-1v-28c0-0.6-0.4-1-1-1
H34.3z M61.3,45.8c0,0.3-0.2,0.5-0.5,0.5H6.3c-0.2,0-0.4-0.2-0.3-0.4c2.6-6,12.2-25.6,28.4-25.6h26.5c0.3,0,0.5,0.2,0.5,0.5V45.8z
"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M31.3,36.2c0,0.6,0.4,1,1,1h24c0.6,0,1-0.4,1-1v-11c0-0.6-0.4-1-1-1H42.5C31.8,24.3,31.3,36.1,31.3,36.2z M48.3,34.8
c0,0.3-0.2,0.5-0.5,0.5h-5c-0.3,0-0.5-0.2-0.5-0.5v-1c0-0.3,0.2-0.5,0.5-0.5h5c0.3,0,0.5,0.2,0.5,0.5V34.8z M42.5,26.3h12.3
c0.3,0,0.5,0.2,0.5,0.5v8c0,0.3-0.2,0.5-0.5,0.5h-4c-0.3,0-0.5-0.2-0.5-0.5v-2.5c0-0.6-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1v2.5
c0,0.3-0.2,0.5-0.5,0.5h-5.9c-0.3,0-0.5-0.3-0.4-0.5C33.9,31.9,35.7,26.3,42.5,26.3z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M3.3,51.3c0,0.6,0.4,1,1,1h58c0.6,0,1-0.4,1-1s-0.4-1-1-1h-58C3.7,50.3,3.3,50.7,3.3,51.3z"
        />
      </g>
    </g>
  </svg>
</template>
