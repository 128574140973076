<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <!-- Generator: Adobe Illustrator 21.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 56.5 59.9"
    style="enable-background:new 0 0 56.5 59.9;"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          :fill="color"
          d="M16.4,15.9c-0.3-0.1-0.6-0.2-0.7-0.3V8.9c0-1.7-2.4-2.6-4.7-2.6c-2.3,0-4.7,0.9-4.7,2.6v6.7c-0.1,0.1-0.4,0.2-0.7,0.3
c-1.7,0.7-5.3,2.2-5.3,9.4v34.1H11h10.9V25.3C21.8,18.1,18.1,16.6,16.4,15.9z M11,7.4c2.1,0,3.6,0.8,3.6,1.5v6.2H7.4V8.9
C7.4,8.2,8.9,7.4,11,7.4z M20.7,58.4H11H1.3v-33c0-6.5,3-7.8,4.7-8.4c0.5-0.2,0.9-0.4,1.2-0.7h7.6c0.2,0.3,0.7,0.5,1.2,0.7
c1.7,0.7,4.8,1.9,4.8,8.4V58.4z"
        />
        <path
          :fill="color"
          d="M22.1,59.7H0V25.3c0-7.4,3.7-8.9,5.5-9.7c0.2-0.1,0.4-0.2,0.5-0.2V8.9C6,7,8.6,6.1,11,6.1c2.4,0,4.9,1,4.9,2.8v6.5
c0.1,0.1,0.4,0.2,0.6,0.2h0c1.8,0.7,5.6,2.3,5.6,9.7V59.7z M0.5,59.2h21.1V25.3c0-7.1-3.6-8.5-5.3-9.2l0.1-0.2l-0.1,0.2
c-0.3-0.1-0.6-0.3-0.8-0.3l-0.1-0.1V8.9c0-1.5-2.3-2.3-4.4-2.3c-2.2,0-4.4,0.9-4.4,2.3v6.8l-0.1,0.1C6.3,15.9,6,16,5.7,16.1
c-1.7,0.7-5.2,2.1-5.2,9.2V59.2z M21,58.6H1.1V25.3c0-6.7,3.1-8,4.8-8.7c0.4-0.2,0.9-0.4,1.1-0.6L7.1,16h7.8l0.1,0.1
c0.2,0.3,0.7,0.4,1.1,0.6c1.7,0.7,4.9,2,4.9,8.7V58.6z M1.6,58.1h18.9V25.3c0-6.3-3-7.5-4.6-8.2c-0.5-0.2-0.9-0.4-1.2-0.7H7.3
C7,16.8,6.6,17,6.1,17.2c-1.6,0.6-4.5,1.9-4.5,8.2V58.1z M14.8,15.4H7.1V8.9c0-0.9,1.8-1.7,3.8-1.7c2.1,0,3.8,0.8,3.8,1.7V15.4z
 M7.6,14.9h6.7v-6c0-0.4-1.3-1.2-3.3-1.2c-2.1,0-3.3,0.8-3.3,1.2V14.9z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M10.8,29.6c-2.4,0-6.4,0.2-6.4,1.7v17.4c0,0.3,0.2,0.5,0.5,0.5h11.5c0.3,0,0.5-0.2,0.5-0.5V31.3
C17,29.9,13.6,29.6,10.8,29.6z M15.9,48.1H5.4V31.4c0.4-0.3,2.4-0.7,5.3-0.7c3,0,4.8,0.4,5.1,0.7V48.1z"
        />
        <path
          :fill="color"
          d="M16.4,49.5H4.9c-0.4,0-0.8-0.4-0.8-0.8V31.3c0-1.3,2.2-2,6.7-2c4.3,0,6.4,0.7,6.4,2v17.4C17.2,49.1,16.8,49.5,16.4,49.5z
 M10.8,29.8c-3.9,0-6.2,0.6-6.2,1.5v17.4c0,0.2,0.1,0.3,0.3,0.3h11.5c0.2,0,0.3-0.1,0.3-0.3V31.3C16.7,30.3,14.5,29.8,10.8,29.8z
 M16.1,48.4H5.2V31.3l0.1-0.1c0.5-0.4,2.5-0.8,5.5-0.8c2.9,0,4.9,0.4,5.3,0.8l0.1,0.1V48.4z M5.7,47.9h9.9V31.5
c-0.4-0.2-2.1-0.6-4.9-0.6c-2.9,0-4.6,0.4-5.1,0.6V47.9z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          :fill="color"
          d="M45.3,29.2c-2.4,0-6.4,0.2-6.4,1.7v17.4c0,0.3,0.2,0.5,0.5,0.5H51c0.3,0,0.5-0.2,0.5-0.5V30.9
C51.5,29.5,48.2,29.2,45.3,29.2z M50.4,47.7H40V31c0.4-0.3,2.4-0.7,5.3-0.7c3,0,4.8,0.4,5.1,0.7V47.7z"
        />
        <path
          :fill="color"
          d="M51,49.1H39.5c-0.4,0-0.8-0.4-0.8-0.8V30.9c0-1.3,2.2-2,6.7-2c4.3,0,6.4,0.7,6.4,2v17.4C51.8,48.7,51.4,49.1,51,49.1z
 M45.3,29.4c-3.9,0-6.2,0.6-6.2,1.5v17.4c0,0.2,0.1,0.3,0.3,0.3H51c0.2,0,0.3-0.1,0.3-0.3V30.9C51.3,30,49.1,29.4,45.3,29.4z
 M50.7,48H39.7V30.9l0.1-0.1c0.5-0.4,2.5-0.8,5.5-0.8c2.9,0,4.9,0.4,5.3,0.8l0.1,0.1V48z M40.2,47.5h9.9V31.1
c-0.4-0.2-2.1-0.6-4.9-0.6c-2.9,0-4.6,0.4-5.1,0.6V47.5z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M55.7,0.2H41.5c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h1.6v4.1h1.5v2.2c-0.2,0-0.4,0-0.6,0c-1.4,0-2.5-0.1-3.1,0.5
c-0.2,0.2-0.4,0.6-0.4,0.9v6.7c-0.1,0.1-0.4,0.2-0.7,0.3c-1.7,0.7-5.3,2.2-5.3,9.4v34.1h10.7H56V25.5c0-7.2-3.7-8.7-5.5-9.4
c-0.3-0.1-0.6-0.2-0.7-0.3V9c0-0.4-0.1-0.7-0.4-0.9c-0.6-0.6-1.7-0.5-3.1-0.5c-0.2,0-0.4,0-0.7,0V5.4h1.4V1.3h8.1v1
c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5V0.8C56.2,0.5,56,0.2,55.7,0.2z M40.2,17c0.5-0.2,0.9-0.4,1.2-0.7h3.3v10.8h-9.1
v-1.6C35.5,19,38.6,17.7,40.2,17z M45.2,58.5h-9.6V28.2h19.4v30.3H45.2z M50.1,17c1.7,0.7,4.8,1.9,4.8,8.4v1.6h-9.1V16.3h3.2
C49.2,16.7,49.6,16.8,50.1,17z M46.4,8.7c1,0,2.1-0.1,2.3,0.2c0,0,0,0,0,0.1v6.2h-7.2V9c0-0.1,0-0.1,0-0.1
c0.3-0.2,1.3-0.2,2.3-0.2c0.4,0,0.8,0,1.2,0C45.6,8.7,46,8.7,46.4,8.7z M46,4.3h-1.8V1.5H46V4.3z"
        />
        <path
          :fill="color"
          d="M56.3,59.9H34.2V25.5c0-7.4,3.7-8.9,5.5-9.7c0.2-0.1,0.4-0.2,0.5-0.2V9c0-0.4,0.2-0.8,0.4-1.1c0.7-0.6,1.8-0.6,3.3-0.6
l0.4,0V5.7h-1.5V1.6h-1.4c-0.4,0-0.8-0.4-0.8-0.8S41.1,0,41.5,0h14.2c0.4,0,0.8,0.4,0.8,0.8v1.6c0,0.4-0.4,0.8-0.8,0.8
s-0.8-0.4-0.8-0.8V1.6h-7.6v4.1h-1.4v1.7c0.1,0,0.3,0,0.4,0c1.5,0,2.6-0.1,3.3,0.6c0.3,0.3,0.4,0.7,0.4,1.1v6.5
c0.1,0.1,0.4,0.2,0.6,0.2c1.8,0.7,5.6,2.3,5.6,9.7V59.9z M34.7,59.4h21.1V25.5c0-7.1-3.6-8.5-5.3-9.2c-0.3-0.1-0.6-0.3-0.8-0.3
l-0.1-0.1V9c0-0.3-0.1-0.6-0.3-0.7c-0.5-0.5-1.6-0.5-2.9-0.4c-0.2,0-0.5,0-0.7,0l-0.3,0V5.2h1.4V1.1h8.6v1.3
c0,0.2,0.1,0.3,0.3,0.3S56,2.6,56,2.4V0.8c0-0.2-0.1-0.3-0.3-0.3H41.5c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3h1.9v4.1h1.5
v2.7l-0.9,0c-1.4,0-2.4-0.1-2.9,0.4c-0.2,0.2-0.3,0.4-0.3,0.7v6.8l-0.1,0.1c-0.1,0.1-0.4,0.2-0.7,0.3c-1.7,0.7-5.2,2.1-5.2,9.2
V59.4z M55.2,58.8H35.3V27.9h19.9V58.8z M35.8,58.3h18.9V28.4H35.8V58.3z M55.2,27.3h-9.6V16.1h3.5l0.1,0.1
c0.2,0.3,0.7,0.4,1.1,0.6c1.7,0.7,4.9,2,4.9,8.7V27.3z M46,26.8h8.6v-1.4c0-6.3-3-7.5-4.6-8.2h0c-0.5-0.2-0.9-0.4-1.2-0.7H46V26.8
z M44.9,27.3h-9.6v-1.9c0-6.7,3.1-8,4.8-8.7l0,0c0.4-0.2,0.9-0.4,1.1-0.6l0.1-0.1h3.7V27.3z M35.8,26.8h8.6V16.6h-2.9
c-0.3,0.3-0.8,0.5-1.2,0.7c-1.6,0.6-4.5,1.9-4.5,8.2V26.8z M49,15.5h-7.7V9c0-0.1,0-0.2,0.1-0.3c0.3-0.3,1.1-0.3,2.5-0.2
c0.8,0,1.7,0,2.4,0l0,0.2l0-0.2c1.3,0,2.2,0,2.5,0.3C49,8.8,49,8.9,49,9V15.5z M41.8,15h6.7V9c-0.3-0.1-1.4-0.1-2.1-0.1l0-0.2
l0,0.3c-0.8,0-1.7,0-2.4,0c-0.7,0-1.8-0.1-2.1,0.1L41.8,15z M48.5,9.1C48.5,9.1,48.5,9.1,48.5,9.1C48.5,9.1,48.5,9.1,48.5,9.1z
 M41.8,9.1C41.8,9.1,41.8,9.1,41.8,9.1C41.8,9.1,41.8,9.1,41.8,9.1z M46.2,4.6H44V1.3h2.3V4.6z M44.5,4.1h1.3V1.8h-1.3V4.1z"
        />
      </g>
    </g>
  </svg>
</template>
