<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 53.8 63.4"
    style="enable-background:new 0 0 53.8 63.4;"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          :fill="color"
          d="M26.9,5C14.8,5,5,14.8,5,26.9C5,39,14.8,48.8,26.9,48.8S48.8,39,48.8,26.9C48.8,14.8,39,5,26.9,5z M26.9,47.9
c-11.6,0-21-9.4-21-21s9.4-21,21-21c11.6,0,21,9.4,21,21S38.5,47.9,26.9,47.9z"
        />
        <path
          :fill="color"
          d="M26.9,49.1c-12.2,0-22.2-9.9-22.2-22.2c0-12.2,9.9-22.2,22.2-22.2c12.2,0,22.2,9.9,22.2,22.2
C49.1,39.1,39.1,49.1,26.9,49.1z M26.9,5.2C15,5.2,5.2,15,5.2,26.9c0,11.9,9.7,21.7,21.7,21.7c11.9,0,21.7-9.7,21.7-21.7
C48.6,15,38.9,5.2,26.9,5.2z M26.9,48.2c-11.7,0-21.3-9.5-21.3-21.3c0-11.7,9.5-21.3,21.3-21.3s21.3,9.5,21.3,21.3
C48.2,38.6,38.6,48.2,26.9,48.2z M26.9,6.1c-11.5,0-20.8,9.3-20.8,20.8c0,11.5,9.3,20.8,20.8,20.8c11.5,0,20.8-9.3,20.8-20.8
C47.7,15.5,38.4,6.1,26.9,6.1z"
        />
      </g>
      <g>
        <path
          :fill="color"
          d="M26.9,0.2c-14.7,0-26.7,12-26.7,26.7c0,14,10.9,25.6,24.7,26.6c0.3,0.4,0.5,0.9,0.5,1.6c-5.5,0.2-13,1.2-13,4
c0,3.2,10.1,4,15.4,4c5.3,0,15.4-0.8,15.4-4c0-2.8-7.8-3.8-13.4-4c0.1-0.7,0.3-1.4,0.6-1.7c0,0,0-0.1,0.1-0.1
c12.9-1.9,22.8-13,22.8-26.4C53.6,12.2,41.6,0.2,26.9,0.2z M42.1,59.1c0,0.9-5,2.7-14.1,2.7S13.9,60,13.9,59.1
c0-0.8,4-2.4,11.5-2.6c-0.1,0.4-0.3,0.8-0.6,1.2c-0.2,0.3-0.1,0.6,0.1,0.8c0.3,0.2,0.6,0.1,0.8-0.1c0.1-0.1,0.1-0.2,0.2-0.3
c0.9-1.5,0.9-3.2,0.4-4.4h3c-0.5,1.2-0.5,2.9,0.4,4.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1
c0.3-0.2,0.3-0.5,0.1-0.8c-0.3-0.4-0.5-0.8-0.6-1.2C37.9,56.7,42.1,58.2,42.1,59.1z M26.9,52.2c-14,0-25.3-11.4-25.3-25.3
S12.9,1.6,26.9,1.6s25.3,11.4,25.3,25.3S40.9,52.2,26.9,52.2z"
        />
        <path
          :fill="color"
          d="M28,63.4c-0.6,0-15.7,0-15.7-4.3c0-3.3,8.8-4,13-4.2c-0.1-0.4-0.2-0.8-0.4-1.1C10.9,52.7,0,40.9,0,26.9
C0,12.1,12.1,0,26.9,0c14.8,0,26.9,12.1,26.9,26.9c0,13.2-9.8,24.6-22.9,26.6c0,0,0,0,0,0c-0.3,0.3-0.5,0.8-0.5,1.3
c3.5,0.1,13.3,0.8,13.3,4.2C43.7,63.3,28.6,63.4,28,63.4z M26.9,0.5C12.3,0.5,0.5,12.3,0.5,26.9c0,13.8,10.8,25.3,24.5,26.3l0.1,0
l0.1,0.1c0.3,0.4,0.5,1.1,0.6,1.7l0,0.3l-0.3,0c-6.2,0.3-12.7,1.4-12.7,3.7c0,2.8,9.1,3.8,15.2,3.8s15.2-1,15.2-3.8
c0-2.4-7.1-3.5-13.1-3.7l-0.3,0l0-0.3c0.1-0.7,0.3-1.4,0.7-1.9l0.1-0.1l0.1-0.1c12.9-1.9,22.6-13.1,22.6-26.1
C53.3,12.3,41.5,0.5,26.9,0.5z M28,62c-8.9,0-14.4-1.7-14.4-3c0-1.2,4.7-2.6,11.8-2.9l0.3,0l-0.1,0.3c-0.1,0.5-0.3,0.9-0.6,1.3
c-0.1,0.1-0.1,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.4-0.1c0-0.1,0.1-0.1,0.1-0.2l0.1-0.1c0.8-1.3,0.9-2.9,0.3-4.2l-0.2-0.3h3.8l-0.2,0.3
c-0.5,1.3-0.4,2.9,0.3,4.2l0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.3,0.2,0.4,0.1c0.1-0.1,0.2-0.3,0.1-0.4
c-0.3-0.4-0.5-0.9-0.6-1.3l-0.1-0.3l0.3,0c7.3,0.2,12.1,1.7,12.1,2.9C42.3,60.3,36.9,62,28,62z M25,56.7
c-7.6,0.3-10.9,1.9-10.9,2.4c0,0.6,4.3,2.5,13.9,2.5c9.5,0,13.9-1.9,13.9-2.5c0-0.5-3.4-2.1-11.3-2.4c0.1,0.3,0.3,0.6,0.4,0.8
c0.2,0.4,0.2,0.9-0.2,1.1c-0.4,0.2-0.9,0.1-1.1-0.2c0-0.1-0.1-0.1-0.1-0.2l-0.1-0.1c-0.8-1.3-1-2.9-0.5-4.3h-2.3
c0.5,1.4,0.3,3-0.5,4.3l-0.1,0.1c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.4-0.8,0.5-1.1,0.2c-0.4-0.2-0.5-0.8-0.2-1.1
C24.8,57.3,24.9,57,25,56.7z M26.9,52.5C12.8,52.5,1.3,41,1.3,26.9S12.8,1.3,26.9,1.3c14.1,0,25.6,11.5,25.6,25.6
S41,52.5,26.9,52.5z M26.9,1.8C13.1,1.8,1.8,13.1,1.8,26.9C1.8,40.7,13.1,52,26.9,52C40.7,52,52,40.7,52,26.9
C52,13.1,40.7,1.8,26.9,1.8z"
        />
      </g>
      <g>
        <path
          id="XMLID_3_"
          d="M24.1,14c0,0-0.1,0-0.2,0L13.1,26.3c0,0,0,0.1,0,0.2c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0l10.8-12.3
C24.1,14.1,24.1,14,24.1,14z"
        />
        <path
          :fill="color"
          d="M13.2,26.7c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.3l10.8-12.3c0.1-0.2,0.4-0.2,0.5,0l0,0
c0.1,0.1,0.2,0.4,0,0.5L13.5,26.6C13.4,26.7,13.3,26.7,13.2,26.7z"
        />
      </g>
      <g>
        <path
          id="XMLID_2_"
          d="M37.6,14c0,0-0.1,0-0.2,0L18.2,35.9c0,0,0,0.1,0,0.2c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0l19.3-21.9
C37.7,14.1,37.7,14,37.6,14z"
        />
        <path
          :fill="color"
          d="M18.2,36.3c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3l19.3-21.9c0.1-0.1,0.2-0.1,0.2-0.1
c0.1,0,0.2,0,0.3,0.1l0,0c0.1,0.1,0.2,0.4,0,0.5L18.5,36.2C18.5,36.3,18.4,36.3,18.2,36.3z M18.4,36.1L18.4,36.1
C18.4,36.1,18.4,36.1,18.4,36.1z M18.1,35.9C18.1,35.9,18.1,35.9,18.1,35.9L18.1,35.9z M37.7,14.2L37.7,14.2
C37.7,14.2,37.7,14.2,37.7,14.2z"
        />
      </g>
      <g>
        <path
          id="XMLID_1_"
          d="M41.4,29.6L29,43.6c0,0,0,0.1,0,0.2c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0l12.4-14c0,0,0-0.1,0-0.2
C41.5,29.5,41.5,29.6,41.4,29.6z"
        />
        <path
          :fill="color"
          d="M29.1,44C29,44,29,44,28.9,44c-0.1-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.3l12.4-14c0.1-0.1,0.1-0.1,0.2-0.1
c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.2s0,0.2-0.1,0.3l-12.4,14C29.3,44,29.2,44,29.1,44z M29.2,43.8L29.2,43.8
C29.2,43.8,29.2,43.8,29.2,43.8z M41.4,29.6C41.4,29.6,41.4,29.6,41.4,29.6L41.4,29.6L41.4,29.6z M41.4,29.6L41.4,29.6L41.4,29.6z
"
        />
      </g>
    </g>
  </svg>
</template>
