<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 61.3 61.3"
    style="enable-background:new 0 0 61.3 61.3;"
    xml:space="preserve"
  >
    <g>
      <path
        :fill="color"
        d="M24.9,43.1c0-1.6-1.3-2.9-2.9-2.9H5.6L1.9,23.7c-0.1-0.5-0.6-0.8-1.1-0.7c-0.5,0.1-0.8,0.6-0.7,1.1l3.8,17.1v3.6L0,60.1
c-0.1,0.5,0.2,1,0.7,1.2c0.1,0,0.2,0,0.2,0c0.4,0,0.8-0.3,0.9-0.7L5.5,46h13.6v14.4c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1V46h1
C23.6,46,24.9,44.7,24.9,43.1z M22,44H5.7v-1.9H22c0.5,0,1,0.4,1,1S22.6,44,22,44z"
      />
      <path
        :fill="color"
        d="M32.6,45.4c0-3.1,0.7-6.2,2.1-9h4.6c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1H22c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h4.6
c1.4,2.8,2.1,5.9,2.1,9c0,5.2-2,10.2-5.7,14.3c-0.4,0.4-0.3,1,0.1,1.4c0.2,0.2,0.4,0.3,0.6,0.3c0.3,0,0.5-0.1,0.7-0.3
c4.1-4.5,6.2-9.9,6.2-15.6c0-3.1-0.6-6.1-1.9-9h3.8c-1.3,2.9-1.9,5.9-1.9,9c0,5.7,2.2,11.1,6.2,15.6c0.2,0.2,0.4,0.3,0.7,0.3
c0.2,0,0.5-0.1,0.6-0.3c0.4-0.4,0.4-1,0.1-1.4C34.5,55.6,32.6,50.6,32.6,45.4z"
      />
      <path
        :fill="color"
        d="M16.3,30.6c-0.5,0-1,0.4-1,1s0.4,1,1,1H45c0.5,0,1-0.4,1-1s-0.4-1-1-1H16.3z"
      />
      <path
        :fill="color"
        d="M57.4,44.9v-3.6l3.8-17.1c0.1-0.5-0.2-1-0.7-1.1c-0.5-0.1-1,0.2-1.1,0.7l-3.7,16.5H39.3c-1.6,0-2.9,1.3-2.9,2.9
c0,1.6,1.3,2.9,2.9,2.9h1v14.4c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1V46h13.6l3.6,14.6c0.1,0.4,0.5,0.7,0.9,0.7c0.1,0,0.2,0,0.2,0
c0.5-0.1,0.8-0.6,0.7-1.2L57.4,44.9z M55.5,44H39.3c-0.5,0-1-0.4-1-1s0.4-1,1-1h16.3V44z"
      />
      <path
        :fill="color"
        d="M1.1,9.6c8-1.1,14.6,2.7,14.7,2.7c0.2,0.1,0.5,0.2,0.7,0.1c4.8-1.3,8.7-1.8,13.2-1.9v17.2c0,0.5,0.4,1,1,1s1-0.4,1-1V10.5
c4.5,0.1,8.4,0.6,13.2,1.9c0.2,0.1,0.5,0,0.7-0.1c0.1,0,6.7-3.9,14.7-2.7c0.5,0.1,0.9-0.2,1-0.6c0.1-0.4,0-0.9-0.4-1.1
C51.8,2.7,41.4,0,30.7,0c0,0,0,0,0,0s0,0,0,0C19.9,0,9.5,2.7,0.5,7.8C0.1,8-0.1,8.5,0.1,8.9C0.2,9.4,0.6,9.6,1.1,9.6z M56,7.5
c-5,0.2-9,1.9-10.7,2.8c-1.1-1.6-4-5.4-9-8C43.2,2.8,49.9,4.6,56,7.5z M30.6,2c6.2,1.7,10.2,5.6,12.1,8C38.6,9,34.9,8.6,30.6,8.6
S22.7,9,18.5,10C20.5,7.6,24.4,3.7,30.6,2z M24.9,2.2c-5,2.7-7.9,6.4-9,8c-1.7-0.8-5.6-2.5-10.7-2.8C11.4,4.6,18,2.8,24.9,2.2z"
      />
    </g>
  </svg>
</template>
